var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "kt_aside",
      staticClass: "aside aside-left d-flex flex-column",
      attrs: { id: "kt_aside" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "aside-brand d-flex flex-column align-items-center flex-column-auto pt-5 pt-lg-13 pb-5",
        },
        [
          _vm.user &&
          _vm.user.ProfilePicture &&
          _vm.user.ProfilePicture.length > 0
            ? _c(
                "div",
                {
                  staticClass: "btn p-0 symbol symbol-60 symbol-light-primary",
                  attrs: { id: "kt_quick_user_toggle" },
                },
                [
                  _c("v-img", {
                    staticStyle: { "border-radius": "10px" },
                    attrs: {
                      alt: "Logo",
                      "lazy-src": "",
                      src: _vm.profilePicture,
                      "max-height": "60",
                      "max-width": "60",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "btn p-0 symbol symbol-60 symbol-light-primary",
                  attrs: { id: "kt_quick_user_toggle" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "symbol-label" },
                    [
                      _c("inline-svg", {
                        staticClass: "h-75",
                        attrs: { alt: "Logo", src: _vm.profilePicture },
                      }),
                    ],
                    1
                  ),
                ]
              ),
          _vm.isCustomer ? _c("StudentChanger") : _vm._e(),
          _c("AcademicYearChanger", { staticClass: "mt-5" }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "aside-nav d-flex flex-column align-items-center flex-column-fluid text-center",
        },
        [
          _c("ul", { staticClass: "nav flex-column" }, [
            _c(
              "li",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.right",
                    value: "ANA SAYFA",
                    expression: "'ANA SAYFA'",
                    modifiers: { hover: true, right: true },
                  },
                ],
                staticClass: "nav-item mb-3",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/dashboard" } },
                  [
                    _c("v-icon", { attrs: { color: "#8950FC", large: "" } }, [
                      _vm._v("mdi-home-variant-outline"),
                    ]),
                    _c("br"),
                    _c("span", [_vm._v("ANA SAYFA")]),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "ÖDEVLERİM",
                        expression: "'ÖDEVLERİM'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/odevlerim" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-book-edit-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("ÖDEVLERİM")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "ÇALIŞMA PLANIM",
                        expression: "'ÇALIŞMA PLANIM'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/calisma-planim" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-notebook-edit-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("ÇALIŞMA PLANI")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            (!_vm.isCustomer || _vm.isProxy) && !_vm.user.IsElementaryStudent
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "ÖDEV RAPORU",
                        expression: "'ÖDEV RAPORU'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/odev-raporu" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-finance")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("ÖDEV RAPORU")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "SINAVLAR",
                        expression: "'SINAVLAR'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/sinavlar" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-file-document-edit-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("SINAVLAR")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "SINAV RAPORU",
                        expression: "'SINAV RAPORU'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/sinav-raporu" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-chart-bar-stacked")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("SINAV RAPORU")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "GENEL KARNE",
                        expression: "'GENEL KARNE'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/genel-karne" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-file-certificate-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("GENEL KARNE")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "İÇERİKLER",
                        expression: "'İÇERİKLER'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/pano" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-thumb-up-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("İÇERİKLER")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "DERS PROGRAMIM",
                        expression: "'DERS PROGRAMIM'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/ders-programi" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-calendar-clock-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("DERS PROGRAMI")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "RANDEVULAR",
                        expression: "'RANDEVULAR'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/randevular" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-briefcase-clock-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("RANDEVULAR")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.right",
                    value: "GÖRÜSMELER",
                    expression: "'GÖRÜSMELER'",
                    modifiers: { hover: true, right: true },
                  },
                ],
                staticClass: "nav-item mb-3",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/gorusmeler" } },
                  [
                    _c("v-icon", { attrs: { color: "#8950FC", large: "" } }, [
                      _vm._v("mdi-comment-account-outline"),
                    ]),
                    _c("br"),
                    _c("span", [_vm._v("GÖRÜŞMELER")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.right",
                    value: "FORMLAR",
                    expression: "'FORMLAR'",
                    modifiers: { hover: true, right: true },
                  },
                ],
                staticClass: "nav-item mb-3",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/formlar" } },
                  [
                    _c("v-icon", { attrs: { color: "#8950FC", large: "" } }, [
                      _vm._v("mdi-checkbox-multiple-marked-outline"),
                    ]),
                    _c("br"),
                    _c("span", [_vm._v("FORMLAR")]),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "DEVAMSIZLIK",
                        expression: "'DEVAMSIZLIK'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/devamsizliklar" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-calendar-remove-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("DEVAMSIZLIKLAR")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "DEVAMSIZLIK",
                        expression: "'DEVAMSIZLIK'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/diger" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-account-key-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("YABANCI DİL UYGULAMALARI")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isCustomer || _vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "SAĞLIK RAPORLARIM",
                        expression: "'SAĞLIK RAPORLARIM'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/saglik-raporlari" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-file-document-plus-outline")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("SAĞLIK RAPORLARI")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.right",
                    value: "BİLDİRİMLER",
                    expression: "'BİLDİRİMLER'",
                    modifiers: { hover: true, right: true },
                  },
                ],
                staticClass: "nav-item mb-3",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/bildirimler" } },
                  [
                    _c("v-icon", { attrs: { color: "#8950FC", large: "" } }, [
                      _vm._v("mdi-bell-outline"),
                    ]),
                    _c("br"),
                    _c("span", [_vm._v("BİLDİRİMLER")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.isCustomer && !_vm.isProxy
              ? _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.right",
                        value: "QR KOD",
                        expression: "'QR KOD'",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "nav-item mb-3",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/qr-kod" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "#8950FC", large: "" } },
                          [_vm._v("mdi-qrcode")]
                        ),
                        _c("br"),
                        _c("span", [_vm._v("QR KOD")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "li",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.right",
                    value: "AYARLAR",
                    expression: "'AYARLAR'",
                    modifiers: { hover: true, right: true },
                  },
                ],
                staticClass: "nav-item mb-3",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/ayarlar" } },
                  [
                    _c("v-icon", { attrs: { color: "#8950FC", large: "" } }, [
                      _vm._v("mdi-cog-outline"),
                    ]),
                    _c("br"),
                    _c("span", [_vm._v("AYARLAR")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", {
        staticClass:
          "aside-footer d-flex flex-column align-items-center flex-column-auto py-8",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }