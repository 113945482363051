var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "ml-1",
          attrs: {
            icon: "",
            href: "#",
            id: "kt_quick_actions_toggle",
            "data-toggle": "tooltip",
            "data-placement": "right",
            "data-container": "body",
            "data-boundary": "window",
            "data-original-title": "Quick Actions",
          },
        },
        [_c("v-icon", [_vm._v("mdi-menu")])],
        1
      ),
      _c(
        "div",
        {
          ref: "kt_quick_actions",
          staticClass: "offcanvas offcanvas-left p-4",
          attrs: { id: "kt_quick_actions" },
        },
        [
          _c(
            "div",
            {
              class:
                "offcanvas-header d-flex align-items-center justify-content-between pb-7 " +
                _vm.iosCustomMargin,
              attrs: { "kt-hidden-height": "50" },
            },
            [_c("AcademicYearChanger", { staticClass: "ml-2" }), _vm._m(0)],
            1
          ),
          _c(
            "div",
            { staticClass: "offcanvas-content pr-5 mr-n5" },
            [
              _c(
                "v-row",
                { staticClass: "gutter-b" },
                [
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/odevlerim" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-book-edit-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" ÖDEVLER ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  (!_vm.isCustomer || _vm.isProxy) && !_vm.isElementaryStudent
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/odev-raporu" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-finance")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" ÖDEV RAPORU ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/sinavlar" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-file-document-edit-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" SINAVLAR ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/sinav-raporu" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-chart-bar-stacked")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" SINAV RAPORU ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  (!_vm.isCustomer || _vm.isProxy) && !_vm.isElementaryStudent
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/calisma-planim" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-notebook-edit-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" ÇALIŞMA PLANI")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/genel-karne" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-file-certificate-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" GENEL KARNE ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/pano" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-thumb-up-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" İÇERİKLER ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/randevular" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-briefcase-clock-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" RANDEVULAR ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-sheet",
                        {
                          attrs: {
                            outlined: "",
                            color: "#8950FC",
                            rounded: "lg",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                              staticStyle: {
                                height: "110px",
                                "border-radius": "0.75rem",
                              },
                              attrs: { to: "/gorusmeler" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-2",
                                  attrs: { color: "#8950FC", "x-large": "" },
                                },
                                [_vm._v("mdi-comment-account-outline")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-block font-weight-bolder font-size-h6 mt-1",
                                },
                                [_vm._v(" GÖRÜŞMELER ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/ders-programi" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-calendar-clock-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" DERS PROGRAMI ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-sheet",
                        {
                          attrs: {
                            outlined: "",
                            color: "#8950FC",
                            rounded: "lg",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                              staticStyle: {
                                height: "110px",
                                "border-radius": "0.75rem",
                              },
                              attrs: { to: "/formlar" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-2",
                                  attrs: { color: "#8950FC", "x-large": "" },
                                },
                                [
                                  _vm._v(
                                    "mdi-checkbox-multiple-marked-outline "
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-block font-weight-bolder font-size-h6 mt-1",
                                },
                                [_vm._v(" FORMLAR ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/devamsizliklar" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-calendar-remove-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" DEVAMSIZLIK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/diger" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-account-key-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" YABANCI DİL UYGULAMALARI ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isCustomer || _vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/saglik-raporlari" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-file-document-plus-outline")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" SAĞLIK RAPORLARI ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-sheet",
                        {
                          attrs: {
                            outlined: "",
                            color: "#8950FC",
                            rounded: "lg",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                              staticStyle: {
                                height: "110px",
                                "border-radius": "0.75rem",
                              },
                              attrs: { to: "/bildirimler" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-2",
                                  attrs: { color: "#8950FC", "x-large": "" },
                                },
                                [_vm._v("mdi-bell-outline")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-block font-weight-bolder font-size-h6 mt-1",
                                },
                                [_vm._v(" BİLDİRİMLER ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isCustomer && !_vm.isProxy
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-sheet",
                            {
                              attrs: {
                                outlined: "",
                                color: "#8950FC",
                                rounded: "lg",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                                  staticStyle: {
                                    height: "110px",
                                    "border-radius": "0.75rem",
                                  },
                                  attrs: { to: "/qr-kod" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-2",
                                      attrs: {
                                        color: "#8950FC",
                                        "x-large": "",
                                      },
                                    },
                                    [_vm._v("mdi-qrcode")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-block font-weight-bolder font-size-h6 mt-1",
                                    },
                                    [_vm._v(" QR KOD ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-sheet",
                        {
                          attrs: {
                            outlined: "",
                            color: "#8950FC",
                            rounded: "lg",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass:
                                "btn btn-block btn-light btn-hover-primary text-dark-50 text-center",
                              staticStyle: {
                                height: "110px",
                                "border-radius": "0.75rem",
                              },
                              attrs: { to: "/ayarlar" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-2",
                                  attrs: { color: "#8950FC", "x-large": "" },
                                },
                                [_vm._v("mdi-cog-outline")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-block font-weight-bolder font-size-h6 mt-1",
                                },
                                [_vm._v(" AYARLAR ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "my-5 text-center", attrs: { cols: "12" } },
                    [
                      !_vm.loading
                        ? _c(
                            "a",
                            {
                              staticClass: "my-5",
                              attrs: { "data-v-1940ce3b": "" },
                              on: { click: _vm.loginPdevco },
                            },
                            [
                              _c("img", {
                                staticClass: "w-32 md:w-auto",
                                staticStyle: {
                                  "max-width": "190px",
                                  "max-height": "60px",
                                },
                                attrs: {
                                  src: "/media/logos/pdevco-logo.png",
                                  "data-v-1940ce3b": "",
                                },
                              }),
                            ]
                          )
                        : _c("v-progress-circular", {
                            staticClass: "my-5",
                            attrs: { color: "success", indeterminate: "" },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-sm btn-icon btn-light btn-hover-danger",
        attrs: { href: "#", id: "kt_quick_actions_close" },
      },
      [_c("i", { staticClass: "ki ki-close icon-sm" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }